import React from "react";
import { Helmet } from "react-helmet";

import AboutUsPageHeader from "./components/AboutUsPageHeader/AboutUsPageHeader";
import OurMission from "./components/OurMission/OurMission";
import OurVision from "./components/OurVision/OurVision";
import OurValues from "./components/OurValues/OurValues";
import OurExpertise from "./components/OurExpertise/OurExpertise";

import "./AboutUsPage.scss";

const AboutUsPage = () => {
  return (
    <div className="aboutUsPage">
      <Helmet>
        <meta
          name="title"
          content="About Us | CONTRACT RESEARCH ORGANISATION | STATECS"
        />
        <meta
          name="description"
          content="At STATECS we provide high-quality clinical data services and solutions to pharmaceutical companies and other CROs from all over the world."
        />
        <title>About Us | CONTRACT RESEARCH ORGANISATION | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <AboutUsPageHeader />
      <OurMission />
      <OurVision />
      <OurValues />
      <OurExpertise />
    </div>
  );
};

export default AboutUsPage;
