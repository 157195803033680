import { getBlogByIdActionEnum } from "../actionType";

export const blogByIdReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case getBlogByIdActionEnum.IS_LOADING_BLOG_BY_ID:
      return { ...state, loading: true };
    case getBlogByIdActionEnum.GET_BLOG_BY_ID_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case getBlogByIdActionEnum.GET_BLOG_BY_ID_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
