export const FOOTER_SERVICES_ITEMS = [
  { content: "Medical Writing", href: "/services/medical-writing" },
  {
    content: "Clinical Data Management",
    href: "/services/clinical-data-management",
  },
  {
    content: "Statistical Programming",
    href: "/services/statistical-programming",
  },
  { content: "Regulatory Affairs", href: "/services/regulatory-affairs" },
];

export const FOOTER_COMPANY_ITEMS = [
  { content: "Expertise", href: "/expertise" },
  { content: "About us", href: "/about-us" },
  { content: "Blog", href: "/blog" },
  { content: "Careers", href: "/careers" },
];

export const FOOTER_LEGAL_ITEMS = [
  { content: "Privacy Policy", path: "/privacy-policy" },
  { content: "Terms of Service", path: "/term-and-conditions" },
];
