import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./StatecsExpertise.scss";

const { Text } = Typography;

const StatecsExpertise = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="statecsExpertise">
      <div className="statecsExpertiseContainer">
        <h2 className="title">STATECS EXPERTISE</h2>
        <Text className="subtitle">
          Over the past 10 years, we have worked on a large variety of clinical
          studies, supporting our clients every step of the way: from the early
          phase of research and analysis to submitting and getting CDISC
          compliant. At STATECS, we do more than provide clinical data services.
          We prioritize a client-centric approach and put our efforts into
          understanding clients’ needs. Whether you are an individual
          entrepreneur, a small organization, or a large enterprise, we will
          define your needs and goals and deliver you the best solutions within
          a mutually agreed deadline.
        </Text>
        <Link to="/expertise" className="seeMoreButton" onClick={scrollToTop}>
          See more
        </Link>
      </div>
    </div>
  );
};

export default StatecsExpertise;
