import React from "react";
import { Row, Carousel } from "antd";

import OurServicesCard from "../OurServicesCard/OurServicesCard";

import { OUR_SERVICES_CONSTANTS } from "../../../../../shared/constants/ourServices.constants";
import "./OurServicesCarousel.scss";

const OurServicesCarousel = () => {
  return (
    <div className="carouselSectionContainer">
      <Row className="carousel" id="carousel">
        <Carousel autoplay>
          {OUR_SERVICES_CONSTANTS?.map(
            ({ title, subtitle, icon, path }, index) => {
              return (
                <OurServicesCard
                  title={title}
                  subtitle={subtitle}
                  icon={icon}
                  key={index}
                  href={path}
                />
              );
            }
          )}
        </Carousel>
      </Row>
    </div>
  );
};

export default OurServicesCarousel;
