import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import ServicesWhyStatecs from "./components/ServicesWhyStatecs/ServicesWhyStatecs";
import ServicesMedicalWritingContent from "./components/ServicesMedicalWritingContent/ServicesMedicalWritingContent";
import StatecsAdvantageSubcard from "../../shared/components/StatecsAdvantageSubcard/StatecsAdvantageSubcard";

import { ADVANTAGE_MEDICAL_WRITING_SUBCARDS_CONSTANTS } from "../../shared/constants/statecsAdvantage.constants";
import "./ServicesMedicalWriting.scss";

const { Text } = Typography;

const ServicesMedicalWriting = () => {
  return (
    <div className="servicesMedicalWriting">
      <Helmet>
        <meta name="title" content="Medical Writing Services | STATECS" />
        <meta
          name="description"
          content="STATECS provides professional medical writing support for all stages of product development and its approval."
        />
        <title>Medical Writing Services | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="servicesMedicalWritingHeader">
        <div className="servicesMedicalWritingHeaderContainer">
          <h1 className="servicesMedicalWritingHeaderTitle">MEDICAL WRITING</h1>
          <Text className="servicesMedicalWritingHeaderSubtitle">
            Depending on your project scope, STAECS medical writers will deliver
            quality, accurate, cost-effective clinical and regulatory documents
            with the best scientific standards.
          </Text>
        </div>
      </div>
      <ServicesMedicalWritingContent />
      <div className="whyStatecsMedicalWriting">
        <ServicesWhyStatecs />
      </div>
      <div className="servicesWhyStatecsSubcards">
        {ADVANTAGE_MEDICAL_WRITING_SUBCARDS_CONSTANTS?.map(
          ({ icon, subtitle, path }, index) => {
            return (
              <StatecsAdvantageSubcard
                icon={icon}
                content={subtitle}
                path={path}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServicesMedicalWriting;
