import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import SiteSearch360 from "site-search-360-react";
import MobileMenu from "./MobileMenu/MobileMenu";
import HeaderItem from "./HeaderItem/HeaderItem";
import { HEADER_ITEMS } from "../../shared/constants/headerItems.constants";

import mobileMenuIcon from "../../assets/images/mobile-menu-icon.png";
import statecsLogo from "../../assets/images/statecs-logo.svg";
import "./AppHeader.scss";

const AppHeader = () => {
  const [contactUsPage, setContactUsPage] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("contact-us")) {
      setContactUsPage(true);
    } else {
      setContactUsPage(false);
    }
  }, [location.pathname, contactUsPage]);

  const openMobileMenuHandler = () => {
    setOpen(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="appHeader">
      <div className="appHeaderBackground" />
      <div className="appHeaderContainer">
        <div className="appHeaderContent">
          <div>
            <Link to="/">
              <img
                src={statecsLogo}
                alt="statecs-logo"
                className="statecsLogo"
                onClick={scrollToTop}
              />
              <img
                src={statecsLogo}
                alt="statecs-logo"
                className="statecsLogoMobileContact"
                onClick={scrollToTop}
              />
            </Link>
          </div>
          <div className="appHeaderItems">
            {HEADER_ITEMS?.map(
              ({ content, path, type, cascadeItems, icon }, index) => {
                return (
                  <HeaderItem
                    content={content}
                    key={index}
                    path={path}
                    type={type}
                    icon={icon}
                    cascadeItems={cascadeItems}
                  />
                );
              }
            )}
            {/* <div className="siteSearch360">
              <SiteSearch360 siteId="statecs.am_1" />
            </div> */}
          </div>
          <div className="mobileMenuIcon">
            <img
              src={mobileMenuIcon}
              alt="mobile-menu-icon"
              onClick={openMobileMenuHandler}
            />
          </div>
        </div>
      </div>

      <MobileMenu open={open} setOpen={setOpen} />
    </div>
  );
};

export default AppHeader;
