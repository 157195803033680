import firstIcon from "../../assets/images/training-first-icon.png";
import secondIcon from "../../assets/images/training-second-icon.png";

export const TRAININGS_CONSTANTS = [
  {
    img: firstIcon,
    title: "SAS Training ",
    path: "/trainings/sas-trainings",
    subtitle:
      "In today's technologically directed world, data is everywhere, even if we do not always realize it! It is particularly fundamental for statistical analysis. At STATECS, we not only provide professional SAS (Statistical Analysis System) service but also an in-depth SAS training program, delivered by experienced and highly qualified specialists. ",
  },
  {
    img: secondIcon,
    title: "Data Management",
    path: "/trainings/data-management-trainings",
    subtitle:
      "In today's technologically directed world, data is everywhere, even if we do not always realize it! It is particularly fundamental for statistical analysis. At STATECS, we not only provide professional SAS (Statistical Analysis System) service but also an in-depth SAS training program, delivered by experienced and highly qualified specialists. ",
  },
];
