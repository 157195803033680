import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVacanciesSelector } from "../../../redux/selector/selector";
import { getVacanciesAction } from "../../../redux/action";

import { Typography } from "antd";
import JoinUsCollapse from "./Collapse/JoinUsCollapse";

import "./JoinUsPageContent.scss";

const { Text } = Typography;

const JoinUsPageContent = () => {
  const dispatch = useDispatch();
  const vacancies = useSelector(getVacanciesSelector);

  useEffect(() => {
    dispatch(getVacanciesAction());
  }, [dispatch]);

  return (
    <div className="joinUsPageContent">
      <Text className="joinUsPageContentTitle">Want to join us?</Text>

      {vacancies?.data?.map(
        ({
          title,
          location,
          job_type,
          category,
          id,
          job_responsibilities,
          required_qualifications,
          job_description,
        }) => {
          return (
            <JoinUsCollapse
              title={title}
              location={location}
              jobType={job_type}
              category={category}
              jobResponsibilities={job_responsibilities}
              requiredQualifications={required_qualifications}
              jobDescription={job_description}
              panelKey={id}
              key={id}
            />
          );
        }
      )}
    </div>
  );
};

export default JoinUsPageContent;
