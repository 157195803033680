import { getBlogRelatedPostsActionEnum } from "../actionType";

export const blogRelatedPostsReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case getBlogRelatedPostsActionEnum.IS_LOADING_BLOG_RELATED_POSTS:
      return { ...state, loading: true };
    case getBlogRelatedPostsActionEnum.GET_BLOG_RELATED_POSTS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case getBlogRelatedPostsActionEnum.GET_BLOG_RELATED_POSTS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
