import React from "react";
import { Link } from "react-router-dom";

import { Collapse, Typography } from "antd";

import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Panel } = Collapse;

const MobileMenuItem = ({ type, content, path, cascadeItems, setOpen }) => {
  const menuCloseHandler = () => {
    window.scrollTo({
      top: 0,
    });

    setOpen(false);
  };
  switch (type) {
    case "cascade":
      return (
        <Collapse
          className="collapse"
          expandIconPosition="end"
          bordered={false}
          accordion={true}
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} className="arrowIcon" />
          )}
        >
          <Panel
            header={
              <Link to={path} className="link" onClick={menuCloseHandler}>
                <Text className="headerItem">{content}</Text>
              </Link>
            }
            key={1}
            className="panel"
          >
            {cascadeItems?.map(({ content, href }, index) => {
              return (
                <Link
                  to={href}
                  className="link"
                  key={index}
                  onClick={menuCloseHandler}
                >
                  <Text className="headerItem">{content}</Text>
                </Link>
              );
            })}
          </Panel>
        </Collapse>
      );

    default:
      return (
        <Link to={path} className="link" onClick={menuCloseHandler}>
          <Text className="headerItem">{content}</Text>
        </Link>
      );
  }
};

export default MobileMenuItem;
