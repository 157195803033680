import React from "react";

import { Typography } from "antd";

import "./ISSISEServicesContent.scss";

const { Text } = Typography;

const ISSISEServicesContent = () => {
  return (
    <div className="iss-ise-services">
      <Text className="text">
        Well-planned and high-quality ISS (Integrated Summary of Safety) and ISE
        (Integrated Summary of Efficacy) are crucial for the successful
        regulatory submission of your Common Technical Document (CTD). Because
        each submission process is specific, it can be challenging to develop
        your integrated summaries on your own. Not to mention that a single
        failure and rejection from the FDA will cause a lot of trouble: an
        approval process that can be delayed for over a year, costly
        resubmission, etc. STATECS is here to save you the trouble. Having
        developed several integrated summaries for a wide range of clients, our
        specialists will surely deliver the highest quality results that comply
        with the whole regulatory process. We will provide you with valuable
        advice on how to properly collect and combine data in your summaries and
        shorten the time wasted on the FDA approval wait.
      </Text>
      <Text className="text">
        ISS and ISE are beneficial for several reasons:
      </Text>

      <ul className="list">
        <li className="subtext">
          Finding out atypical/unusual trends in patient subgroups
        </li>
        <li className="subtext">Improving the accuracy of the results</li>
        <li className="subtext">
          Risk and benefit assessment based on the comparison of variation in
          the study
        </li>
        <li className="subtext">Achieving a strong statistical conclusion</li>
      </ul>
    </div>
  );
};

export default ISSISEServicesContent;
