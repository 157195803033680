import { getBlogByIdActionEnum } from "../actionType";
import { GetBlogById } from "../service/service";

export const getBlogByIdAction = (id) => async (dispatch) => {
  dispatch({ type: getBlogByIdActionEnum.IS_LOADING_BLOG_BY_ID });
  try {
    const data = await GetBlogById(id);

    dispatch({
      type: getBlogByIdActionEnum.GET_BLOG_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getBlogByIdActionEnum.GET_BLOG_BY_ID_ERROR,
      error: error,
    });
  }
};
