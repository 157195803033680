import React from "react";

import { Typography } from "antd";

import "./AfterTrainingsCard.scss";

const { Text } = Typography;

const AfterTrainingsCard = ({ icon, content }) => {
  return (
    <div className="afterTrainingsCard">
      <img
        src={icon}
        alt="after-trainings-card-icon"
        className="afterTrainingsCardIcon"
      />
      <Text className="afterTrainingsCardContent">{content}</Text>
    </div>
  );
};
export default AfterTrainingsCard;
