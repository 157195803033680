import React from "react";

import { Typography } from "antd";

import "./ServicesInfo.scss";

const { Text } = Typography;

const ServicesInfo = () => {
  return (
    <div className="servicesInfo">
      <Text className="infoContent">
        Give your product a guarantee of success and ensure FDA approval with
        our full package of clinical development solutions.
      </Text>
      <Text className="infoContent">
        The key to a successful regulatory submission can be found in the
        quality and preparation process of the data. An experienced full-service
        CRO like STATECS brings experience and quality to all the aspects of
        clinical development, from medical documentation creation to clinical
        trial reporting and successful submission.
      </Text>
      <Text className="infoContent">
        With our full stack services at your hand, you can be sure that your
        data and products are guaranteed to succeed.
      </Text>
    </div>
  );
};

export default ServicesInfo;
