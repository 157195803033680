import React from "react";

import { Typography } from "antd";

import "./ProgramOverview.scss";

const { Text } = Typography;

const ProgramOverview = () => {
  return (
    <div className="programOverview">
      <div className="programOverviewContainer">
        <h2 className="programOverviewTitle">Program Overview</h2>
        <Text className="programOverviewSubtitle">
          Our training programs are designed to provide in-depth practical
          knowledge and skills so that each participant is fully prepared for
          hands-on experience and knows how to use the provided knowledge to
          solve real life situations. STATECS training programs usually last 1-3
          months depending on the program and all the successful participants
          who show great results during the program get an opportunity to go
          pass an internship and get hired at STATECS.
        </Text>
      </div>
      <div className="programOverviewBackground" />
    </div>
  );
};

export default ProgramOverview;
