import React from "react";

import { Typography } from "antd";

import ourMission from "../../../../assets/images/our-mission.png";
import "./OurMission.scss";

const { Text } = Typography;

const OurMission = () => {
  return (
    <div className="ourMission">
      <Text className="ourMissionHeader">
        Within our long years of practice, we have worked on numerous big and
        small projects, from the early stages of research and analysis to the
        successful submission and confirmation by the FDA. Our list of
        successful cooperations includes several large industry organizations
        from all over the world, including Europe, the USA, and other major
        markets.
      </Text>
      <div className="ourMissionContainer">
        <div className="ourMissionTextContent">
          <Text className="ourMissionTextContentTitle">OUR MISSION</Text>
          <Text className="ourMissionTextContentSubtitle">
            Our mission is to provide high-quality clinical data services and
            solutions to customers from all over the world. At STATECS, we
            provide not only a wide range of services and solutions to our
            clients, but we also build long-term partnerships through trust,
            care, quality work, and a personalized approach for each customer.
            These are the foundational values of STATECS, which help us to work
            harder and move forward towards achieving our desired goals.
          </Text>
        </div>
        <img src={ourMission} alt="our-mission" className="ourMissionCover" />
      </div>
    </div>
  );
};

export default OurMission;
