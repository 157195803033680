import { postContactForTrainingsActionEnum } from "../actionType";

export const contactForTrainingsReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case postContactForTrainingsActionEnum.IS_LOADING_CONTACT_FOR_TRAININGS:
      return { ...state, loading: true };
    case postContactForTrainingsActionEnum.POST_CONTACT_FOR_TRAININGS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case postContactForTrainingsActionEnum.POST_CONTACT_FOR_TRAININGS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
