import React from "react";

import { Typography } from "antd";
import StatecsAdvantageCard from "../../../../shared/components/StatecsAdvantageCard/StatecsAdvantageCard";

import { ADVANTAGE_MEDICAL_WRITING_CONSTANTS } from "../../../../shared/constants/statecsAdvantage.constants";
import "./ServicesWhyStatecs.scss";

const { Text } = Typography;

const ServicesWhyStatecs = () => {
  return (
    <div className="servicesWhyStatecs">
      <Text className="servicesWhyStatecsTitle">WHY STATECS?</Text>
      <Text className="servicesWhyStatecsText">
        STATECS medical writers are backed up with more than 10 years of
        experience in the field and have a comprehensive knowledge of all the
        stages of clinical trial regulations and guidelines. By adapting to your
        demands and utilizing our extensive knowledge, we will turn your
        clinical drug's complex data into an accurate and interpretable
        explanation on time.
      </Text>
      <Text className="servicesWhyStatecsSubtitle">
        WHAT YOU GET WITH STATECS:
      </Text>
      <div className="servicesWhyStatecsCards">
        {ADVANTAGE_MEDICAL_WRITING_CONSTANTS?.map(
          ({ icon, title, subtitle }, index) => {
            return (
              <StatecsAdvantageCard
                icon={icon}
                title={title}
                subtitle={subtitle}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServicesWhyStatecs;
