import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";
import AfterTrainingsCard from "../../../shared/components/AfterTrainingsCard/AfterTrainingsCard";
import AfterTrainingCarousel from "./AfterTrainingCarousel/AfterTrainingCarousel";

import { AFTER_TRAININGS_CONSTANTS } from "../../../shared/constants/afterTrainings.constants";
import "./AfterSASTraining.scss";

const { Text } = Typography;

const AfterSASTraining = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="afterSASTraining">
      <div className="afterSASTrainingHeader">
        <Text className="afterSASTrainingHeaderTitle">
          During our 1 month of intensive SAS training, you will be able to
          build extensive statistical knowledge from the very basics to complex
          models. With this program, you will be able to fully understand the
          data flow in SAS, learn how to do data analysis & management as well
          as use these skills to interpret data and obtain useful results.
        </Text>
      </div>
      <div className="afterSASTrainingSection" id="afterSASTrainingSection">
        <Text className="afterSASTrainingSectionSubtitle">
          After this thorough training,
        </Text>
        <Text className="afterSASTrainingSectionSubtitle">
          you are guaranteed to master SAS and be able to manipulate large
          amounts of data to solve daily problems and acquire valuable insights.
          Besides that, all the successful participants who show great
          performance will have a chance to go through 1-3 month internship at
          STATECS and then get hired at the company.
        </Text>
        <Link
          to="/trainings/apply-sas-trainings"
          className="afterSASTrainingSectionApplyButton"
          onClick={scrollToTop}
        >
          Apply
        </Link>
        <Text className="afterSASTrainingSectionCardsTitle">
          What will you get?
        </Text>
        <div className="afterTrainingsCards">
          {AFTER_TRAININGS_CONSTANTS?.map(({ icon, content }, index) => {
            return (
              <AfterTrainingsCard icon={icon} content={content} key={index} />
            );
          })}
        </div>
        <AfterTrainingCarousel />
      </div>
    </div>
  );
};

export default AfterSASTraining;
