import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import JoinUsPageContent from "./JoinUsPageContent/JoinUsPageContent";

import "./JoinUsPage.scss";

const { Text } = Typography;

const JoinUsPage = () => {
  return (
    <div className="joinUsPage">
      <Helmet>
        <meta name="title" content="Careers | Join STATECS" />
        <meta
          name="description"
          content="We are a team of young data enthusiasts ready to revolutionise the healthcare and medical systems worldwide. Take your opportunity to join STATECS."
        />
        <title>Careers | Join STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="joinUsPageHeader">
        <div className="joinUsPageHeaderContainer">
          <Text className="joinUsPageHeaderTitle">Join us</Text>
          <Text className="joinUsPageHeaderSubtitle">
            STATECS is a team of young data enthusiasts ready to revolutionize
            the healthcare and medical systems worldwide. Hence, we consider the
            reliability and precision of our work to be vital for streamlining
            operations in the world's healthcare systems in general. We aim to
            invest in top talents to deliver scientifically accurate data, which
            will help build a better healthcare future for the upcoming
            generation.
          </Text>
        </div>
      </div>
      <JoinUsPageContent />
    </div>
  );
};

export default JoinUsPage;
