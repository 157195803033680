import React from "react";

import { Typography } from "antd";
import StatecsAdvantageCard from "../../../../shared/components/StatecsAdvantageCard/StatecsAdvantageCard";

import { ADVANTAGE_REGULATORY_AFFAIRS_CONSTANTS } from "../../../../shared/constants/statecsAdvantage.constants";
import "./ServicesWhyStatecs.scss";

const { Text } = Typography;

const ServicesWhyStatecs = () => {
  return (
    <div className="servicesWhyStatecs">
      <Text className="servicesWhyStatecsTitle">WHY STATECS?</Text>
      <Text className="servicesWhyStatecsText">
        STATECS regulatory affairs specialists will prevent you from going
        through all the time-consuming and costly processes by providing
        professional advice on all the legal and scientific restraints and
        requirements. We will carefully analyze your content and handle the
        entire submission process for you from beginning to end. We will also
        ensure that your application gets accepted by the FDA and corresponds to
        the highest standards and prerequisites. STATECS will also provide you
        with CDISC compliance and ensure the necessary standards are covered.
      </Text>
      <Text className="servicesWhyStatecsSubtitle">
        WHAT MAKES US DIFFERENT
      </Text>
      <div className="servicesWhyStatecsCards">
        {ADVANTAGE_REGULATORY_AFFAIRS_CONSTANTS?.map(
          ({ icon, subtitle }, index) => {
            return (
              <StatecsAdvantageCard
                icon={icon}
                subtitle={subtitle}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServicesWhyStatecs;
