import React from "react";

import { Typography } from "antd";

import "./StatecsAdvantageCard.scss";

const { Text } = Typography;

const StatecsAdvantageCard = ({ icon, title, subtitle }) => {
  return (
    <div className="statecsAdvantageCard">
      {title && (
        <div className="statecsAdvantageCardHeader">
          <img src={icon} className="icon" alt="icon" />
          {title ? <Text className="title">{title}</Text> : ""}
        </div>
      )}
      {!title && <img src={icon} className="singleIcon" alt="icon" />}
      <Text className="subtitle">{subtitle}</Text>
    </div>
  );
};

export default StatecsAdvantageCard;
