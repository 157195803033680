import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import arrow from "../../../assets/images/arrow-right.svg";
import "./BlogPageItemCard.scss";

const { Text } = Typography;

const BlogPageItemCard = ({ img, title, subtitle, id }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="blogPageItemCard">
      <div
        className="blogPageItemCardImage"
        style={{
          backgroundImage: img
            ? `url(${img})`
            : "url(../../../../assets/images/blog-card-background.png",
        }}
      />
      <div className="blogPageItemCardContent">
        <Text className="title">{title}</Text>
        <Text className="subtitle">{subtitle}</Text>
        <div className="seeMore">
          <Link to={`/blog/${id}`} onClick={scrollToTop}>
            <div className="seeMoreButton">
              <Text className="seeMoreButtonText">See more</Text>
              <img src={arrow} alt="arrow" className="arrow" />
            </div>
          </Link>
        </div>
        <div className="blogPageItemCardFooter">
          <Text className="counter">view: 1000</Text>
          <Text className="zeel">by ZEEL Blog</Text>
        </div>
      </div>
    </div>
  );
};

export default BlogPageItemCard;
