import React from "react";

import { Typography } from "antd";

import "./FunctionServiceProvidersContent.scss";

const { Text } = Typography;

const FunctionServiceProvidersContent = () => {
  return (
    <div className="function-service-providers">
      <Text className="subtext">
        Should you run out of qualified specialists, we are here to lend a hand!
      </Text>
      <Text className="text">
        Outsourcing different business operations has long become a new norm for
        many companies around the world. It reduces costs, increases
        flexibility, and gives you access to a larger talent pool. Why not take
        advantage of it?
      </Text>
      <Text className="text">
        Our experienced statisticians, programmers, clinical data managers,
        pharmacovigilance scientists, and medical writers are here to help you
        execute your clinical trial operations and complement your in-house
        team.
      </Text>
      <Text className="text">
        Take the chance to accelerate your data collection, monitoring,
        management, and maintenance without jeopardizing the quality. Our team
        will help you cover your pipeline needs while you focus on what you do
        best. Decreased need to waste finances on hiring an in-house team of
        specialists or having to trust individual contractors
      </Text>
      <Text className="subtext">
        There is more to FSPs (functional service providers) than one can think
        of:
      </Text>
      <Text className="subtext">
        Easier access to the skills and expertise that you lack
      </Text>
      <Text className="subtext">
        Possibility to use functional service providers only for as long as you
        need them
      </Text>
      <Text className="subtext">
        A reliable collaboration that can prevent you from a single point of
        failure
      </Text>
      <Text className="subtext">
        Faster execution of tasks with efficiency and quality
      </Text>
      <Text className="subtext">
        More time to focus on your core specialization
      </Text>
      <Text className="subtext">
        Decreased need to waste finances on hiring an in-house team of
        specialists or having to
      </Text>
      <Text className="subtext">trust individual contractors</Text>
      <Text className="subtext">
        Arrange a meeting with one of our specialists now and receive your
        personalized FSP solution to improve your clinical trial process and
        achieve the expected results.
      </Text>
    </div>
  );
};

export default FunctionServiceProvidersContent;
