import React from "react";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  return (
    <div>
      <Helmet>
        <meta name="title" content="Contact Us | STATECS" />
        <meta
          name="description"
          content="Do you want to partner with STATECS or have any questions? Please, leave your message."
        />
        <title>Contact Us | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    </div>
  );
};

export default ContactUsPage;
