import React from "react";

import { Typography } from "antd";

import "./CDISCCompliantMappingContent.scss";

const { Text } = Typography;

const CDISCCompliantMappingContent = () => {
  return (
    <div className="cdisc-compliant-mapping">
        <Text className="subtext">We will cover all your regulatory compliance needs</Text>
        <Text className="subtext">CDISC Explained</Text>
        <Text className="text">Clinical Data Interchange Standards Consortium (CDISC) is a global not-for-profit organization that determines data standards in the pharmaceutical industry based on the experience and knowledge of volunteers (with relevant education/skills). These standards are implemented during the analysis, reporting, and regulatory submission of medical data.</Text>
        <Text className="text">The major standards supported by CDISC are SDTM (Study Data Tabulation Model) and ADaM (Analysis Data Model). The former accepts raw study data mapping and collating, while the latter works with analysis data. STATECS team is highly experienced in mapping all CDISC standards.</Text>
        <Text className="list-title">There are a lot of benefits to complying with the CDISC standards:</Text>
        <ul className="list">
        <li className="subtext">
        Medical research transparency
        </li>
        <li className="subtext">Timeline and cost reduction during drug development</li>
        <li className="subtext">FDA reviewers spend less time on the data reviewal thanks to these standards
        </li>
        <li className="subtext">Easier exchange of high-quality data between sponsors and CROs</li>
      </ul>
        <Text className="list-title">Our CDISC services include:</Text>
        

        <ul className="list">
        <li className="subtext">
        Customized CDISC solutions
        </li>
        <li className="subtext">Production of databases that are compliant with CDISC standards</li>
        <li className="subtext">CDISC SDTM domain creation</li>
        <li className="subtext">Clinical study mapping to CDISC standards for successful FDA submission as well as ISS/ISE reporting</li>
        <li className="subtext">Ensuring the compatibility with SDTM &#38; ADaM regardless of the version at hand</li>
        <li className="subtext">CDISC ODM XML to SAS® datasets conversion and vice versa</li> 
      </ul>

      <Text className="text">We will help you determine which data structures to convert to, when, and why. Our expertise lets us make the most of all the data collected as a result of various clinical trials. Choosing us you choose a customer-oriented policy, human-centered approach, high-quality services, and trustful and reliable collaboration.</Text>


















      
       
    </div>
  );
};

export default CDISCCompliantMappingContent;
