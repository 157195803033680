import React from "react";

import "./BlogPage.scss";

const BlogPage = () => {
    window.location.href = 'https://blog.statecs.com';
    return null;
};

export default BlogPage;
