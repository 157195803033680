import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import {
  vacanciesReducer,
  blogReducer,
  blogByIdReducer,
  blogRelatedPostsReducer,
  contactUsReducer,
  getBlogPageReducer,
  sasTrainingsReducer,
  dmTrainingsReducer,
  contactForTrainingsReducer,
  newsletterSubscriptionReducer,
  applyForJobReducer,
} from "../reducer";

const rootReducer = combineReducers({
  vacancies: vacanciesReducer,
  blog: blogReducer,
  blogPage: getBlogPageReducer,
  blogById: blogByIdReducer,
  blogRelatedPosts: blogRelatedPostsReducer,
  contactUs: contactUsReducer,
  sasTrainings: sasTrainingsReducer,
  dmTrainings: dmTrainingsReducer,
  contactForTrainings: contactForTrainingsReducer,
  newsletterSubscription: newsletterSubscriptionReducer,
  applyJob: applyForJobReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
