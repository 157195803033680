import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import ServicesWhyStatecs from "./components/ServicesWhyStatecs/ServicesWhyStatecs";
import RegulatoryAffairsContent from "./components/RegulatoryAffairsContent/RegulatoryAffairsContent";
import StatecsAdvantageSubcard from "../../shared/components/StatecsAdvantageSubcard/StatecsAdvantageSubcard";

import { ADVANTAGE_REGULATORY_AFFAIRS_SUBCARDS_CONSTANTS } from "../../shared/constants/statecsAdvantage.constants";
import "./RegulatoryAffairs.scss";

const { Text } = Typography;

const RegulatoryAffairs = () => {
  return (
    <div className="regulatoryAffairs">
      <Helmet>
        <meta name="title" content="Regulatory Affairs Services | STATECS" />
        <meta
          name="description"
          content="STATECS regulatory affairs specialists will  provide you with professional advice on all the legal and scientific restraints and requirements."
        />
        <title>Regulatory Affairs Services | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="regulatoryAffairsHeader">
        <div className="regulatoryAffairsHeaderContainer">
          <h1 className="regulatoryAffairsHeaderTitle">REGULATORY AFFAIRS</h1>
          <Text className="regulatoryAffairsHeaderSubtitle">
            Our regulatory affairs team will use their extensive practice and
            knowledge to ensure your submissions get approved according to
            global standards.
          </Text>
        </div>
      </div>
      <RegulatoryAffairsContent />
      <div className="whyStatecsMedicalWriting">
        <ServicesWhyStatecs />
      </div>

      <div className="servicesOurAdvantages">
        <Text className="servicesOurAdvantagesTitle">
          WHAT YOU GET WITH STATECS:
        </Text>
        <ul>
          <li>High Quality Service</li>
          <Text className="listSubItem">
            We always strive for high quality and 100% on-time metrics for all
            our clients.
          </Text>
          <li>Customized Approach</li>
          <Text className="listSubItem">
            At STATECS, we prefer a customer-centric approach that fits each
            project.
          </Text>
          <li>24/7 Customer Support</li>
          <Text className="listSubItem">
            With us, you get professional 24/7 customer support ready to answer
            all your queries.
          </Text>
        </ul>
      </div>
      <div className="servicesWhyStatecsSubcards">
        {ADVANTAGE_REGULATORY_AFFAIRS_SUBCARDS_CONSTANTS?.map(
          ({ icon, subtitle, path }, index) => {
            return (
              <StatecsAdvantageSubcard
                icon={icon}
                content={subtitle}
                key={index}
                path={path}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default RegulatoryAffairs;
