import { getVacanciesActionEnum } from "../actionType";
import { GetVacancies } from "../service/service";

export const getVacanciesAction = () => async (dispatch) => {
  dispatch({ type: getVacanciesActionEnum.IS_LOADING_VACANCIES });
  try {
    const data = await GetVacancies();

    dispatch({
      type: getVacanciesActionEnum.GET_VACANCIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getVacanciesActionEnum.GET_VACANCIES_ERROR,
      error: error,
    });
  }
};
