import React from "react";

import { Typography } from "antd";

import "./AnalysisAndModelingContent.scss";

const { Text } = Typography;

const AnalysisAndModelingContent = () => {
  return (
    <div className="analysis-and-modeling">
      <Text className="text">
        Clinical pharmacology is the study of drug flow through the body or its
        interaction with the body. In other words, it studies the way drug
        behaves in the body and how the body reacts to the drug after that.
        Pharmaceutical companies use the knowledge gathered from PK and PD
        analysis to create more effective clinical trials (i.e., what dose to
        use or how different drugs interact with each other in the body).
      </Text>
      <Text className="text">
        Clinical pharmacology is divided into two central categories:
        pharmacokinetics (PK) and pharmacodynamics (PD). Pharmacokinetics and
        pharmacodynamics assess and describe the intricate biochemical
        interactions that take place between a drug's chemical composition and
        the body's natural processes. These interactions play a crucial role in
        evaluating a drug's safety and effectiveness.
      </Text>
      <Text className="text">
        PK studies help drug makers to understand the particular dose-response
        relationship (or the impact of other factors, such as blood pressure,
        heart rate, etc.) on the blood or plasma concentrations of a drug in a
        variety of patient groups. In addition to that, PK research aids to
        identify the need for dose alterations based on PK variations between
        demographic categories or populations with impaired elimination.
        Moreover, PK studies also serve as a foundation for therapeutic drug
        monitoring in rare groups and helping in the treatment of certain
        cancers.
      </Text>
      <Text className="text">
        PK &#38; PD modeling is typically used in the pre-clinical and clinical
        stages of drug research. It offers a logical foundation for
        patient-specific individualization treatment, which could lead to
        applied pharmacotherapy in clinical treatment and performed at a higher
        level. Our PK &#38; PD analysis services include the following:
      </Text>
      <ul className="list">
        <li className="subtext">
          Study design, PK sampling optimization, and sample size estimation
        </li>
        <li className="subtext">Modeling of the non-compartmental PK</li>
        <li className="subtext">Compartmental PK modeling</li>
        <li className="subtext">Input into Clinical Study Reports</li>
        <li className="subtext">
          Interpretation &#38; design of preclinical ADME and clinical studies
        </li>
        <li className="subtext">Production of PK Handover Documents</li>
        <li className="subtext">
          PD analysis with a variety of graphical and statistical approaches
        </li>
        <li className="subtext">
          Methods for linear and nonlinear mixed modeling in PK/PD
        </li>
        <li className="subtext">
          Statistical analyses such as bioavailability, drug-drug interactions,
          bioequivalence, etc.
        </li>
        <li className="subtext">
          Simulation helps predict systemic exposure for alternative dosing
          regimens
        </li>
      </ul>
    </div>
  );
};

export default AnalysisAndModelingContent;
