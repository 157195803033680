import React from "react";

import ServicesSectionCard from "./ServicesSectionCard/ServicesSectionCard";
import ServicesSectionCarousel from "./ServicesSectionCarousel/ServicesSectionCarousel";

import { SERVICES_CONSTANTS } from "../../../../shared/constants/servicesPage.constants";
import "./ServicesSection.scss";

const ServicesSection = () => {
  return (
    <div className="servicesSection">
      <div className="servicesSectionCards">
        {SERVICES_CONSTANTS.map(({ title, subtitle, icon, url }, index) => {
          return (
            <ServicesSectionCard
              title={title}
              subtitle={subtitle}
              icon={icon}
              url={url}
              key={index}
            />
          );
        })}
      </div>

      <ServicesSectionCarousel />
    </div>
  );
};

export default ServicesSection;
