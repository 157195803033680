import React from "react";
import CountUp from "react-countup";

import { Typography } from "antd";

import "./StatisticsSectionCard.scss";

const { Text } = Typography;

const StatisticsSectionCard = ({ inNumbers, title, subtitle }) => {
  return (
    <div className="statisticsSectionCard">
      <Text className="inNumbers">
        <CountUp start={0} end={inNumbers} duration={1.1} separator="," />+
      </Text>
      <Text className="title">{title}</Text>
      <Text className="subtitle">{subtitle}</Text>
    </div>
  );
};

export default StatisticsSectionCard;
