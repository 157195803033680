import React from "react";

import { Typography } from "antd";
import StatecsAdvantageCard from "../../../../shared/components/StatecsAdvantageCard/StatecsAdvantageCard";

import { ADVANTAGE_CLINICAL_DATA_MANAGEMENT_CONSTANTS } from "../../../../shared/constants/statecsAdvantage.constants";
import "./ServicesWhyStatecs.scss";

const { Text } = Typography;

const ServicesWhyStatecs = () => {
  return (
    <div className="servicesWhyStatecs">
      <Text className="servicesWhyStatecsTitle">WHY STATECS?</Text>
      <Text className="servicesWhyStatecsText">
        STATECS team strives to show a personalized approach to each client
        through tailored solutions regarding their needs and goals. While we
        fully adhere to international CDISC compliant standards, our teams of
        data management experts build a strategic and customized approach for
        each study grounding our perspective in the broader development goals of
        the companies we support. This practice allows us to provide quality,
        consistency, and efficiency for each project execution.
      </Text>
      <Text className="servicesWhyStatecsSubtitle">
        WHAT MAKES US DIFFERENT :
      </Text>
      <div className="servicesWhyStatecsCards">
        {ADVANTAGE_CLINICAL_DATA_MANAGEMENT_CONSTANTS?.map(
          ({ icon, subtitle }, index) => {
            return (
              <StatecsAdvantageCard
                icon={icon}
                subtitle={subtitle}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServicesWhyStatecs;
