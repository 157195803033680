import React from "react";

import { Modal, Typography } from "antd";

import successIcon from "../../../assets/images/success-modal-icon.png";
import "./SuccessModal.scss";

const { Text } = Typography;

const SuccessModal = ({
  isModalVisible,
  setIsModalVisible,
  title,
  subtitle,
}) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="joinUsModalContainer">
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: "625px" }}
        className="successModal"
        footer=""
      >
        <img src={successIcon} alt="success-icon" />
        <Text className="successModalTitle">{title}</Text>
        <Text className="successModalSubtitle">{subtitle}</Text>
        <div className="successModalButton" onClick={handleOk}>
          Okay
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
