import React from "react";
import { EXPERTISE_PNELS_CONSTANTS } from "../../../../shared/constants/expertisePanels.constants";

import CollapseComponent from "./Collapse/Collapse";

import "./ExpertiseContent.scss";

const ExpertiseContent = () => {
  return (
    <div className="expertiseContent">
      {EXPERTISE_PNELS_CONSTANTS?.map(
        ({ icon, title, subtitle, content }, index) => {
          return (
            <CollapseComponent
              icon={icon}
              title={title}
              subtitle={subtitle}
              panelKey={index}
              text={subtitle}
              content={content}
              key={index}
            />
          );
        }
      )}
    </div>
  );
};

export default ExpertiseContent;
