import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { applyTrainingsAction } from "../../../redux/action";

import { Col, Form, Input, Button, Upload, Typography, Checkbox } from "antd";
import SuccessModal from "../../../shared/components/SuccessModal/SuccessModal";

import "./TrainingApplyForm.scss";

const { Text } = Typography;
const { TextArea } = Input;
const FormItem = Form.Item;

const TrainingApplyForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileType, setFileType] = useState();
  const [state, setState] = useState({
    file: null,
    base64URL: "",
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.file?.type) {
      setFileType(state.file.type.split("/"));
    }
  }, [state?.file]);

  const onFinish = async (values) => {
    dispatch(
      applyTrainingsAction(
        {
          ...values,
          cv: state.base64URL,
          extension: fileType?.[fileType?.length - 1],
          type: "sas",
        },
        (status) => {
          if (status.type === "success") {
            setIsModalVisible(true);
          }
        }
      )
    );

    form.resetFields();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let { file } = state;

    file = e.file;

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setState({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        setState({
          file: err,
        });
      });

    setState({
      file: e.file,
    });

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div id="trainingApplyForm" className="trainingApplyForm">
      <div className="trainingApplyFormContainer">
        <Form
          form={form}
          name="applyTrainingForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className="joinUsModal"
        >
          <div className="formHeader">
            <Text className="formTitle">For Applying</Text>
            <Text className="formSubtitle">Send your details</Text>
          </div>

          <Col className="form-items">
            <FormItem
              name="name"
              rules={[
                { required: true, message: "Please input your full name!" },
              ]}
            >
              <Input placeholder="Full Name*" />
            </FormItem>

            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email*!",
                },
              ]}
            >
              <Input placeholder="Email*" />
            </FormItem>

            <FormItem
              name="phone"
              rules={[
                {
                  pattern: new RegExp(
                    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i //eslint-disable-line
                  ),
                  required: true,
                  message: "Please input your phone!",
                },
              ]}
            >
              <Input placeholder="Phone*" />
            </FormItem>

            <FormItem name="linkedin_profile">
              <Input placeholder="Linkedin Profile Link" />
            </FormItem>

            <FormItem name="cover_letter" className="formItemTextArea">
              <TextArea placeholder="Cover Letter" />
            </FormItem>
          </Col>

          <FormItem>
            <FormItem
              name="cv"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please upload your cv!",
                },
              ]}
              getValueFromEvent={handleFileInputChange}
            >
              <Upload.Dragger
                name="files"
                multiple={false}
                beforeUpload={() => {
                  return false;
                }}
              >
                <p className="ant-upload-text">Upload CV / Resume*</p>
                <p className="ant-upload-hint">
                  Drug &#38; Drop your files or Browse
                </p>
              </Upload.Dragger>
            </FormItem>
          </FormItem>

          <FormItem
            name="agree"
            valuePropName="checked"
            rules={[{ required: true, message: "Please check!" }]}
          >
            <Checkbox>
              By Using This Form You Agree With The Storage And Handling Of Your
              Data By This Website.*
            </Checkbox>
          </FormItem>

          <Col className="submitButtonContainer">
            <Button type="primary" htmlType="submit" className="submitButton">
              Submit
            </Button>
          </Col>
        </Form>
      </div>
      <SuccessModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title="Thank you for applying"
        subtitle="You will be notified via
        Email about further steps"
      />
    </div>
  );
};

export default TrainingApplyForm;
