export const STATISTICS_CONSTANTS = [
  {
    inNumbers: 250,
    title: "Completed Projects",
    subtitle:
      "Our dedication to work, expertise in the field, and deep understanding of client needs have led to the completion of 250+ successful projects.",
  },
  {
    inNumbers: 70,
    title: "Motivated Team Members",
    subtitle:
      "At STATECS, we believe that no success can be achieved without a great team, so we grow our team and are happy to share our success with 70+ employees. ",
  },
  {
    inNumbers: 150,
    title: "Satisfied Clients",
    subtitle:
      "Over 10 years of experience in the field allowed us to build long-term and effective cooperation with more than 150 clients worldwide.",
  },
  {
    inNumbers: 15,
    title: " International Markets",
    subtitle:
      "We have no market limitations. Our scope of markets includes different continents. We have so far been successful in managing projects for clients from Europe and the US.",
  },
];
