import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import ServicesWhyStatecs from "./components/ServicesWhyStatecs/ServicesWhyStatecs";
import StatisticalProgrammingContent from "./components/StatisticalProgrammingContent/StatisticalProgrammingContent";
import StatecsAdvantageSubcard from "../../shared/components/StatecsAdvantageSubcard/StatecsAdvantageSubcard";

import { ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_SUBCARDS_CONSTANTS } from "../../shared/constants/statecsAdvantage.constants";
import "./StatisticalProgramming.scss";

const { Text } = Typography;

const StatisticalProgramming = () => {
  return (
    <div className="statisticalProgramming">
      <Helmet>
        <meta
          name="title"
          content="Statistical Programming Services | STATECS"
        />
        <meta
          name="description"
          content="STATECS Statistical Programming department's programmers turn large amounts of medical data into definable information and give the best solutions."
        />
        <title>Statistical Programming Services | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="statisticalProgrammingHeader">
        <div className="statisticalProgrammingHeaderContainer">
          <h1 className="statisticalProgrammingHeaderTitle">
            Statistical Programming
          </h1>
          <Text className="statisticalProgrammingHeaderSubtitle">
            WE DELIVER A COMPLETE PACKAGE OF SERVICES, DETAILED MEDICAL DATA
            ANALYSIS AND MANAGEMENT TO COMPLEX STATISTICAL PROGRAMMING.
          </Text>
        </div>
      </div>
      <StatisticalProgrammingContent />
      <div className="whyStatecsMedicalWriting">
        <ServicesWhyStatecs />
      </div>
      <div className="servicesWhyStatecsSubcards">
        {ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_SUBCARDS_CONSTANTS?.map(
          ({ icon, subtitle, path }, index) => {
            return (
              <StatecsAdvantageSubcard
                icon={icon}
                content={subtitle}
                path={path}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default StatisticalProgramming;
