import React from "react";

import { Typography } from "antd";

import "./BayesianAdaptiveClinicalTrialDesignesContent.scss";

const { Text } = Typography;

const BayesianAdaptiveClinicalTrialDesignesContent = () => {
  return (
    <div className="bayesian-adaptive-clinical-trial-designes">
      <Text className="text">
        The role of Contract Research Organizations (CROs) — and particularly
        cancer-focused CROs — has become more crucial in recent years as the
        number of cancer clinical trials has accelerated globally. These
        organizations can assist sponsors in carrying out phase I–IV oncology
        studies across continents in an effective, timely, and compliant manner.
      </Text>
      <Text className="text">
        Small and mid-sized cancer-centric CROs provide clinical trial sponsors
        with a large variety of strategic benefits and useful features, among
        which are the following:
      </Text>

      <ul className="list">
        <li className="subtext">
          An in-depth understanding of the patient’s condition
        </li>
        <li className="subtext">
          Prior interactions with oncology key opinion leaders (KOLs)
        </li>
        <li className="subtext">
          Established relationships with global cancer clinical site networks
        </li>
      </ul>

      <Text className="text">
        If you are a drug development company that frequently runs oncology
        trials, then it is crucial for you to choose a Contract Research
        Organization (CRO) with extensive experience in the oncology therapeutic
        area. Choosing the right CRO with the proper experience will help you
        achieve your objectives of enhancing patient lives despite the specific
        hurdles posed by the complexity of oncology studies.
      </Text>
      <Text className="text">
        Moreover, selecting a partner who specializes in data instead of a
        standard full-service CRO can make all the difference in your study's
        performance because oncology trials confront significant hurdles in the
        collecting, processing, and reporting of study data.
      </Text>
      <Text className="text">
        We believe that a research team with particular expertise and training
        is needed to meet the challenges of oncology clinical trials. Our
        capacity to respond to the particular research needs of our clients and
        our pool of collective experience differentiates us in the market as a
        strong choice.
      </Text>
      <Text className="title">WHY STATECS:</Text>
      <Text className="text">
        At STATECS, we have large expertise in managing a number of vendors at
        the same time. We have collaborated extensively with third-party vendors
        and some of the major pharmaceutical firms on their cancer studies to
        guarantee that all the data required for oncology trials are collected,
        centralized, and prepared for analysis.
      </Text>

      <ul className="list">
        <li className="subtext">
          10+ Years experience in managing oncology clinical trials worldwide
        </li>
        <li className="subtext">
          Global oncology CRO solutions with a full package of services
        </li>
        <li className="subtext">
          A number of successful studies across all clinical phases (I-IV)
        </li>
      </ul>

      <Text className="title">AREAS OF FOCUS</Text>
      <Text className="text">
        STATECS areas of focus in Oncology include the following:
      </Text>

      <ul className="list">
        <li className="subtext">
          Research and examination of newly developed oncolytic technologies
        </li>
        <li className="subtext">
          Full awareness of changes and improvements to cancer clinical trial
          monitoring techniques
        </li>
        <li className="subtext">
          Analysis of oncology clinical trials landscape for particular patient
          populations and indications
        </li>
        <li className="subtext">
          Examination of the current technology used in clinical monitoring,
          diagnosis, and treatment
        </li>
      </ul>
    </div>
  );
};

export default BayesianAdaptiveClinicalTrialDesignesContent;
