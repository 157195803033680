import React from "react";

import { Modal } from "antd";
import SiteSearch360 from "site-search-360-react";

import MobileMenuItem from "./MobileMenuItem/MobileMenuItem";

import { HEADER_ITEMS } from "../../../shared/constants/headerItems.constants";
import statecsLogo from "../../../assets/images/statecs-logo-colorful.png";
import "./MobileMenu.scss";

const MobileMenu = ({ open, setOpen }) => {
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="joinUsModalContainer">
      <Modal
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: "100%" }}
        className="mobileMenuModal"
        footer=""
      >
        <div className="mobileMenuModalContainer">
          <img
            src={statecsLogo}
            alt="statecs-logo-colorful"
            className="statecsLogoColorful"
          />
          <SiteSearch360 siteId="statecs.am_1" />
          {HEADER_ITEMS?.map(
            ({ content, path, type, cascadeItems, icon }, index) => {
              return (
                <MobileMenuItem
                  content={content}
                  key={index}
                  path={path}
                  type={type}
                  icon={icon}
                  cascadeItems={cascadeItems}
                  setOpen={setOpen}
                />
              );
            }
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MobileMenu;
