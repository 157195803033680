import managementIcon from "../../assets/images/our-services-management-icon.png";
import medicalIcon from "../../assets/images/our-services-medical-icon.png";
import regulatoryAffairsIcon from "../../assets/images/our-services-regulatory-affairs- icons.png";
import statisticsIcon from "../../assets/images/our-services-statistics-icon.svg";

export const SERVICES_CONSTANTS = [
  {
    icon: medicalIcon,
    title: "Medical Writing",
    url: "/services/medical-writing",
    subtitle:
      "Having well-written, clear, and precise clinical documentation is a must for any medical and healthcare organization. Our team of experienced medical writers will handle all your clinical documentation from regulatory documents to research reports and everything in between according to the highest standards.",
  },
  {
    icon: managementIcon,
    title: "Clinical Data Management",
    url: "/services/clinical-data-management",
    subtitle:
      "Clinical Data Management (CDM) is a critical part of clinical research, which helps to collect, manage and maintain data from clinical trials. The result of great data management is a high quality database that is accurate, relevant, reliable, and complete for further analysis.",
  },
  {
    icon: statisticsIcon,
    title: "Statistical Programming",
    url: "/services/statistical-programming",
    subtitle:
      "STATECS programmers build and provide powerful statistical programming software which allows the generation of complex diagnostic data and production of Tables, Listings & Figures (TLFs). This significantly helps to accelerate and simplify the overall process of clinical procedures.",
  },
  {
    icon: regulatoryAffairsIcon,
    title: "Regulatory Affairs",
    url: "/services/regulatory-affairs",
    subtitle:
      "Experience & proficiency in Pharmaceutical Regulatory Affairs are fundamental for the successful submission and approval of your application by worldwide organizations of standards. The regulatory affairs experts at STATECS will handle the entire process for you and make sure that your submission gets approved by FDA, CDISC (SDTM) or any leading organization.",
  },
];
