import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { checkHeadingHandler } from "../../../shared/utils/checkHeading";

import { useDispatch, useSelector } from "react-redux";
import { getBlogByIdAction } from "../../../redux/action";
import { getBlogByIdSelector } from "../../../redux/selector/selector";

import { Typography } from "antd";

import facebookIcon from "../../../assets/images/facebook.png";
import linkedinIcon from "../../../assets/images/linkedin.png";
import "./BlogSinglePageContent.scss";

const { Text } = Typography;

const BlogSinglePageContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(getBlogByIdSelector);

  useEffect(() => {
    dispatch(getBlogByIdAction(id));
  }, [dispatch, id]);

  return (
    <div className="blogSinglePageContent">
      <Text className="title">{data?.title ? data?.title : "—"}</Text>
      <div className="dateContainer">
        <Text className="date">{data?.date ? data?.date : "—"}</Text>
        <div className="social">
          <a
            target="_blanck"
            href="https://www.linkedin.com/company/statecs-llc/"
          >
            <img
              src={linkedinIcon}
              alt="statecs-linkedin"
              className="statecsLinkedinSocialIcon"
            />
          </a>

          <a target="_blanck" href="https://www.facebook.com/statecsllc">
            <img src={facebookIcon} alt="statecs-facebook" />
          </a>
        </div>
      </div>

      <div className="blogContent">
        {data?.data?.map((section, index) => {
          return (
            <div className="section" key={index}>
              {checkHeadingHandler(index, section?.title, "sectionTitle")}
              {!!section.image_url && (
                <img
                  src={section.image_url}
                  alt={section.image_alt}
                  className="sectionImage"
                />
              )}

              {!!section.text && (
                <div className="textContentWrapper">
                  {section?.text?.split("\r\n").map((row) => {
                    if (row.includes("&#x2022")) {
                      const textWithDot = row?.replace(/&#x2022/g, "•");
                      return (
                        <p className="sectionContentLeft">{textWithDot}</p>
                      );
                    }

                    if (row.includes(`\point`)) {
                      const textWithPadding = row?.replace(/\\point/g, "");
                      return (
                        <p className="sectionContentLeft">{textWithPadding}</p>
                      );
                    }

                    return <p className="sectionContent">{row}</p>;
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {data?.image_url ? (
        <img
          src={data?.image_url}
          alt="statecs-blog"
          className="statecsBlogImageBig"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogSinglePageContent;
