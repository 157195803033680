import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import BlogSinglePageContent from "./BlogSinglePageContent/BlogSinglePageContent";
import BlogRelatedPostsCarousel from "./BlogRelatedPostsCarousel/BlogRelatedPostsCarousel";
import BlogRelatedPostsCarouselMobile from "./BlogRelatedPostsCarouselMobile/BlogRelatedPostsCarouselMobile";

import { blogRelatedPostsSelector } from "../../redux/selector/selector";

import "./BlogSinglePage.scss";

const BlogSinglePage = () => {
  const { state } = useLocation();
  const { data } = useSelector(blogRelatedPostsSelector);

  return (
    <div className="blogSinglePage">
      <Helmet>
        <meta
          name="title"
          content={
            state?.meta_title
              ? state?.meta_title
              : `STATECS | BLOG${!!state?.title && ` | ${state?.title}`}`
          }
        />
        <meta
          name="description"
          content={
            state?.meta_description
              ? state?.meta_description
              : "SAS (Statistical Analysis Software) is widely used in various industries, including healthcare. SAS is software suite for data analysis and visualization."
          }
        />
        <title>
          STATECS | BLOG
          {!!state?.title && ` | ${state?.title}`}
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="blogSinglePageHeader" />
      <BlogSinglePageContent />
      {!!data?.length && <BlogRelatedPostsCarousel />}
      {!!data?.length && <BlogRelatedPostsCarouselMobile />}
    </div>
  );
};

export default BlogSinglePage;
