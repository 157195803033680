export const checkHeadingHandler = (index, content, className) => {
  if (index + 1 === 1) {
    return <h1 className={className}>{content}</h1>;
  }

  if (index + 1 === 2) {
    return <h2 className={className}>{content}</h2>;
  }

  if (index + 1 === 3) {
    return <h3 className={className}>{content}</h3>;
  }

  if (index + 1 === 4) {
    return <h4 className={className}>{content}</h4>;
  }

  if (index + 1 === 5) {
    return <h5 className={className}>{content}</h5>;
  }

  if (index + 1 >= 6) {
    return <h6 className={className}>{content}</h6>;
  }
};
