import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "antd";
import ContactUsForm from "./ContactUsForm/ContactUsForm";

import "./ContactUs.scss";

const { Text } = Typography;

const ContactUs = () => {
  const [contactUsPageContent, setContactUsPageContent] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("contact-us")) {
      setContactUsPageContent(true);
    } else {
      setContactUsPageContent(false);
    }
  }, [location.pathname]);

  return (
    <div className={contactUsPageContent ? "contactUsPage" : "contactUs"}>
      <div className="textContentBackground" />
      <div className="contactUsContainer">
        <div className="contactUsTextContent">
          <Text className="contactUsTextContentTitle">CONTACT US</Text>
          <Text className="contactUsTextContentSubtitle">
            Want to partner with us or have any questions?
          </Text>
          <Text className="contactUsTextContentSubtitle">
            Leave your message below and we will reach out to you shortly!
          </Text>
        </div>
        <div className="contactUsFormContainer">
          <ContactUsForm />
        </div>
      </div>
      <div className="contactUsFormBackground" />
    </div>
  );
};

export default ContactUs;
