import React, { useState } from "react";

import { Typography } from "antd";
import { Collapse } from "antd";
import JoinUsModal from "./JoinUsModal/JoinUsModal";

import arrowTop from "../../../../assets/images/arrow-top.svg";
import "./JoinUsCollapse.scss";

const { Text } = Typography;
const { Panel } = Collapse;

const JoinUsCollapse = ({
  title,
  location,
  jobType,
  panelKey,
  category,
  jobResponsibilities,
  requiredQualifications,
  jobDescription,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const jobResponsibilitiesList = jobResponsibilities?.split("\r\n");
  const requiredQualificationsList = requiredQualifications?.split("\r\n");
  const jobDescriptionList = jobDescription?.split("\r\n");

  return (
    <div className="joinUsCollapseContainer">
      <Collapse
        className="joinUsCollapse"
        expandIconPosition="end"
        bordered={false}
      >
        <Panel
          header={
            <div className="joinUsPanelHeader">
              <div className="titleContainer">
                <Text className="joinUsPanelHeaderTitle">{title}</Text>
                <Text className="joinUsPanelHeaderLocation">{location}</Text>
              </div>
              <div className="departmentContainer">
                <Text className="departmentTitle">{category}</Text>
                <Text className="departmentContent">{jobType}</Text>
              </div>
            </div>
          }
          key={`${panelKey}`}
          className="joinUsCollapsePanel"
        >
          <div className="joinUsCollapseContent">
            <div className="collapseContent">
              <div className="aboutJob">
                <div className="jobResponsibilities">
                  <Text className="jobResponsibilitiesTitle">
                    Job responsibilities
                  </Text>
                  <div className="jobDataList">
                    {jobResponsibilitiesList?.map((item, index) => {
                      return (
                        <Text key={index} className="jobDataListItem">
                          {item}
                        </Text>
                      );
                    })}
                  </div>
                </div>
                <div className="minimumQualifications">
                  <Text className="minimumQualificationsTitle">
                    Minimum qualifications
                  </Text>
                  <div className="jobDataList">
                    {requiredQualificationsList?.map((item, index) => {
                      return (
                        <Text key={index} className="jobDataListItem">
                          {item}
                        </Text>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="personalQualities">
                <Text className="personalQualitiesTitle">
                  Personal Qualities
                </Text>
                <div className="jobDataList">
                  {jobDescriptionList?.map((item, index) => {
                    return (
                      <Text key={index} className="jobDataListItem">
                        {item}
                      </Text>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="collapseContentFooter">
              <div className="howToApply">
                <Text className="howToApplyTitle">How to apply?</Text>
                <Text className="howToApplyTitleSubtitle">
                  Interested candidates are welcome to send their resume to the
                  following hr@statecs.com email.
                </Text>
              </div>
              <div className="applyButton" onClick={showModal}>
                Apply
              </div>
              <JoinUsModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                id={panelKey}
              />
            </div>
          </div>
          <img src={arrowTop} alt="arrow-top" />
        </Panel>
      </Collapse>
    </div>
  );
};

export default JoinUsCollapse;
