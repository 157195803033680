import { postContactUsActionEnum } from "../actionType";
import { ContactUs } from "../service/service";

export const contactUsAction = (data, callback) => async (dispatch) => {
  dispatch({ type: postContactUsActionEnum.IS_LOADING_CONTACT_US });
  try {
    const response = await ContactUs(data);

    dispatch({
      type: postContactUsActionEnum.POST_CONTACT_US_SUCCESS,
      payloda: response,
    });
    callback({ type: "success" });
  } catch (error) {
    dispatch({
      type: postContactUsActionEnum.POST_CONTACT_US_ERROR,
      payload: error,
    });
    callback({ type: "faild" });
  }
};
