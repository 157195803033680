import React from "react";

import { Typography } from "antd";
import ServiceCard from "../../../../shared/components/ServiceCard/ServiceCard";

import { CLINICAL_DATA_MANAGEMENT_SERVICES_CONSTANTS } from "../../../../shared/constants/servicesSubPage.constants";
import "./ClinicalDataManagementContent.scss";

const { Text } = Typography;

const ClinicalDataManagementContent = () => {
  return (
    <div className="clinicalDataManagementContent">
      <Text className="clinicalDataManagementTextContent">
        Clinical Data Management (CDM) is a principal stage in clinical research
        that helps to generate high-quality, reliable data following regulatory
        standards. CDM is the process of collecting and managing research data
        gathered from clinical trials. The purpose of Clinical Data Management
        is to ensure that the collected data is interpretable, accurate,
        error-free, and valuable for the successful start of the next stages. If
        done correctly, the process of clinical data management will
        significantly accelerate drug development, evaluation, and marketing.
      </Text>
      <Text className="clinicalDataManagementTextContent">
        Because clinical data management is crucial for clinical research and
        drug evaluation, data monitoring and management should comply with
        internationally accepted quality assurance standards. Our clinical data
        managers provide high-quality clinical data management services that
        meet the SDTM (Study Data Tabulation Model Implementation Guide for
        Human Clinical Trials) and CDASH (Clinical Data Acquisition Standards
        Harmonization) standards.
      </Text>
      <h2 className="clinicalDataManagementContentTitle">
        CLINICAL DATA MANAGEMENT SERVICES:
      </h2>
      <Text className="clinicalDataManagementTextContent">
        The qualified team of STATECS data managers will handle the entire
        process of your clinical trials from beginning to completion while
        providing the full spectrum of services needed to execute successful
        clinical data management.
      </Text>
      <Text className="clinicalDataManagementContentSubtitle">
        Our services include but are not limited to:
      </Text>
      <div className="clinicalDataManagementCards">
        {CLINICAL_DATA_MANAGEMENT_SERVICES_CONSTANTS?.map(
          ({ content }, index) => {
            return <ServiceCard content={content} key={index} />;
          }
        )}
      </div>
      <Text className="clinicalDataManagementTextContent">
        Clinical data management is a complex procedure that demands detailed
        attention to every aspect. When it comes to clinical research, drug
        evaluation, patient health and safety, and the advancement of
        healthcare, the CDM plays a crucial role.
      </Text>
    </div>
  );
};

export default ClinicalDataManagementContent;
