import useFetch from "../../shared/useFetch/useFetch";

export const GetVacancies = () => useFetch("get", `api/vacancies/`);

export const GetBlog = (page, itemPerPage) =>
  useFetch("get", `api/blog/?page=${page}&items_per_page=${itemPerPage}`);

export const GetBlogById = (id) => useFetch("get", `api/blog/${id}/`);

export const GetBlogPage = () => useFetch("get", `api/blog/page-count/`);

export const GetBlogRelatedPosts = (id) =>
  useFetch("get", `api/blog/related-posts/?id=${id}`);

export const ContactUs = (data) => useFetch("post", `api/contact-us/`, data);

export const ContactForTrainings = (data) =>
  useFetch("post", `api/trainings/apply`, data);

export const PostNewsletterSubscription = (data) =>
  useFetch("post", `api/newsletter-subscription/`, data);

export const ApplyJob = (data) => useFetch("post", `api/vacancies/apply`, data);

export const GetSASTrainings = () => useFetch("get", `api/trainings/sas/`);

export const GetDMTrainings = () =>
  useFetch("get", `api/trainings/data-management/`);
