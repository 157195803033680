export const MEDICAL_WRITING_CONSTANTS = [
  { content: "Protocol development (synopses and amendments included)" },
  { content: "Informed consent forms" },
  { content: "Clinical study reports" },
  { content: "Development safety update reports" },
  { content: "Investigator brochures" },
  { content: "Independent review charter" },
  { content: "Patient material" },
  { content: "Investigator meeting material" },
  { content: "Regulatory authority responses" },
  {
    content:
      "Multiple types of support documents needed for product submission & launch",
  },
  { content: "Manuscripts and study reports" },
  {
    content:
      "Slide kits, claim substantiation, and dossiers for ongoing market support",
  },
];

export const STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS = [
  { content: "SAS and R programming" },
  { content: "Macro production, validation, and optimization" },
  { content: "Interim analysis support" },
  { content: "Annual clinical trial safety updates" },
  { content: "CDISC mapping/conversion possibilities" },
  {
    content:
      "CDISC ADaM submission-ready dataset development and QC for submission compliance",
  },
  {
    content:
      "Support and mapping of safety data for Data Monitoring Committee (DMC), Data Safety Monitoring Boards (DSMB), Safety Committee and Endpoint Committee",
  },
  {
    content:
      "Analysis Datasets and TLFs Development, and QC for standard studies and Integrated Summaries",
  },
];

export const CLINICAL_DATA_MANAGEMENT_SERVICES_CONSTANTS = [
  {
    content:
      "CDISC/CDASH compliant CRF/eCRF design, monitoring, and maintenance",
  },
  { content: "Setup and implementation of medical coding" },
  { content: "Electronic data collection" },
  { content: "Data management plan development" },
  { content: "Development of SAS listing" },
  {
    content: "eSource & ePRO/eCOA",
  },
  {
    content: "Wearable sensor data flow ",
  },
  {
    content:
      "Import and reconciliation of external data to ensure that the information has been transferred correctly ",
  },
];

export const REGULATORY_AFFAIRS_SERVICES_CONSTANTS = [
  {
    content:
      "Data formatting and submission review to ensure they meet the proper data standards.",
  },
  {
    content:
      "Discovering uncommon data patterns that can make an FDA reviewer skeptical of the quality of your study and lead them to investigate further.",
  },
];
