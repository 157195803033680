import React from "react";

import { Typography } from "antd";

import "./Slogan.scss";

const { Text } = Typography;

const Slogan = () => {
  return (
    <div className="homePageSlogan">
      <div className="homePageSloganContent">
        <Text className="sloganContentTitle">
          Innovating today for a healthier tomorrow
        </Text>
        <Text className="sloganContentSubtitle">
          <h1 className="sloganContentSubtitle">
            STATECS is a leading international <span className="bold">CRO</span>
          </h1>
          , providing advanced data analytics, clinical research, healthcare
          technology solutions, and other related services.
        </Text>
      </div>
    </div>
  );
};

export default Slogan;
