import React from "react";

import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import ContactUs from "../ContactUs/ContactUs";
import NewsletterSubscription from "../NewsletterSubscription/NewsletterSubscription";

import "./AppLayout.scss";

const AppLayout = ({ children }) => {
  return (
    <div className="layout">
      <AppHeader />

      <div className="content"> {children}</div>
      <ContactUs />
      <NewsletterSubscription />
      <AppFooter />
    </div>
  );
};

export default AppLayout;
