import { getDMTrainingsActionEnum } from "../actionType";
import { GetDMTrainings } from "../service/service";

export const getDMTrainingsAction = () => async (dispatch) => {
  dispatch({ type: getDMTrainingsActionEnum.IS_LOADING_DM_TRAININGS });
  try {
    const data = await GetDMTrainings();

    dispatch({
      type: getDMTrainingsActionEnum.GET_DM_TRAININGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getDMTrainingsActionEnum.GET_DM_TRAININGS_ERROR,
      error: error,
    });
  }
};
