import React from "react";

import { Typography } from "antd";
import OurValuesCard from "./OurValuesCard/OurValuesCard";

import { ABOUT_US_VALUES_CONSTANTS } from "../../../../shared/constants/aboutUsValues.constants";
import "./OurValues.scss";

const { Text } = Typography;

const OurValues = () => {
  return (
    <div className="ourValues">
      <div className="ourValuesConatiner">
        <Text className="ourValuesTitle">OUR VALUES</Text>
        <div className="ourValuesCards">
          {ABOUT_US_VALUES_CONSTANTS?.map(({ title, subtitle }, index) => {
            return (
              <OurValuesCard title={title} subtitle={subtitle} key={index} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurValues;
