import firstIcon from "../../assets/images/after-trainings-first-item.png";
import secondIcon from "../../assets/images/after-trainings-second-item.png";
import thirdIcon from "../../assets/images/after-trainings-third-item.png";
import fourthIcon from "../../assets/images/after-trainings-fourth-item.png";
import fivethIcon from "../../assets/images/after-trainings-fiveth-item.png";
import dmIcon from "../../assets/images/after-trainings-icon-dm.png";

export const AFTER_TRAININGS_CONSTANTS = [
  {
    icon: firstIcon,
    content: "An extensive SAS knowledge with all bits and pieces",
  },
  {
    icon: secondIcon,
    content: "Possibility to join STATECS' team of experts",
  },
  {
    icon: thirdIcon,
    content: "Trained by experienced professionals",
  },
  { icon: fourthIcon, content: "To know our friendly and motivated team." },
  {
    icon: fivethIcon,
    content: "A fun and interactive learning experience",
  },
];

export const AFTER_DM_TRAININGS_CONSTANTS = [
  {
    icon: thirdIcon,
    content: "Get trained by experienced team of professionals",
  },
  {
    icon: dmIcon,
    content: "An extensive SAS & DM knowledge with all bits and pieces",
  },
  {
    icon: secondIcon,
    content: "Possibility to join STATECS' team of experts",
  },
  {
    icon: fourthIcon,
    content: "Trained by experienced professionals",
  },
  {
    icon: fivethIcon,
    content: "A fun and interactive learning experience",
  },
];
